<template>
  <div>
    <b-card class="timeseries-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" mode="multiple" :autoLocationSelected="autoLocationSelected" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group class="mb-0">
            <option-chart :label="$t('option_chart')" :showScale="true" :showFillData="true" :resample="{ show: true, methods: methodsResample, opResampleTemp: opResampleTemp }" :selectTimeseries="selectTimeseries" :shape="false" :options="typeList" :typeList="typeList" :nameComponent="$options.name" :typeLayout="typeLayout" />
          </b-form-group>
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" :resampleCount="resampleCount" />
          <Downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadCsv="downloadCsv" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay class="timeseries-container" :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm" no-fade>
      <TimeseriesChart ref="chart" :checkMultiYAxis="checkMultiYAxis" :chart="chart" :showYear="showYear" :nameComponent="$options.name" :indexDatasourceSeleted="indexDatasourceSeleted" @updateEventPlotlyLayout="eventPlotlyLayout" />
    </b-overlay>

    <Timeplayer ref="timeplayer" v-model="date" :liveMode="liveMode" :refreshTime="refreshTime" :moveToEnd="moveToEnd" />
    <AutoGroups :groupData="autoGroupData" :autoGroup="autoGroup" />
  </div>
</template>

<script>
const _ = require('lodash')
const dayjs = require('dayjs')
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin'
import ScaleMixin from '@/mixins/ScaleMixin'
import ChartYearMixin from '@/mixins/ChartYearMixin'
import PausePlayerMixin from '@/mixins/PausePlayerMixin'
import TimerMixin from '@/mixins/TimerMixin'
import Timeplayer from '../../common/Timeplayer.vue'
import SummaryData from '../../common/SummaryData.vue'
import Downloader from '../../common/Downloader.vue'
import RadioGroup from '../../common/RadioGroup.vue'
import OptionChart from '../../common/OptionChartPlotly.vue'
import CheckboxSingle from '../../common/CheckboxSingle.vue'
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue'
import TimeseriesChart from './TimeseriesChartComponent.vue'
import AutoGroups from '../../common/AutoGroups.vue'
import { splitByLastIndex } from '@/utilities/StringUtility.js'
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin'
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors'
import { replaceNaWithLinearInterpolation, replaceNaWithForwardValue, replaceNaWithZero, scaleLog10, scaleLog10Plus, scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js'

const TYPE_LIST = [
  { value: 'lines', text: 'lines_title', icon: require('/static/images/timeseries/lines.svg').default },
  { value: 'markers', text: 'markers_title', icon: require('/static/images/timeseries/markers.svg').default },
  { value: 'lines+markers', text: 'lines_markers_title', icon: require('/static/images/timeseries/lines+markers.svg').default },
]

const TYPE_LAYOUT = [
  { value: '1x0', text: '1x0', icon: require('/static/images/timeseries/1x0.svg').default },
  { value: '2x0', text: '2x0', icon: require('/static/images/timeseries/2x0.svg').default },
  { value: '1x1', text: '1x1', icon: require('/static/images/timeseries/1x1.svg').default },
  { value: '1x2', text: '1x2', icon: require('/static/images/timeseries/1x2.svg').default },
  { value: '2x1', text: '2x1', icon: require('/static/images/timeseries/2x1.svg').default },
  { value: '2x2', text: '2x2', icon: require('/static/images/timeseries/2x2.svg').default },
]

const FILL_DATA = ['na-zero', 'front-fill', 'linear-fill']

export default {
  name: 'timeseries',
  components: { TimeseriesChart, Timeplayer, DatasourceSelect, SummaryData, Downloader, RadioGroup, CheckboxSingle, OptionChart, AutoGroups },
  mixins: [SelectDatasourceMixin, PausePlayerMixin, SelectedGroupMixin, ScaleMixin, ChartYearMixin, TimerMixin, ToastificationContent],
  data() {
    return {
      fillDataArr: FILL_DATA,
      typeLayout: TYPE_LAYOUT,
      typeList: TYPE_LIST,
      chart: { data: [], layout: {} },
      chartData: [],
      resampleCount: 1,
      moveToEnd: 0,
      showOverlay: false,
      renderDate: [],
      autoLocationSelected: [],
      unselectLocation: [],
      setGridTimeseries: { rows: 1, columns: 1, pattern: 'independent' },
      canvasSize: 1024,
      indexDatasourceSeleted: 0,
      flagSelectTimeseries: false,
      checkZoom: [false, false, false, false],
      dateZoom: [
        ['0000-00-00 00:00:00', '0000-00-00 00:00:00'],
        ['0000-00-00 00:00:00', '0000-00-00 00:00:00'],
        ['0000-00-00 00:00:00', '0000-00-00 00:00:00'],
        ['0000-00-00 00:00:00', '0000-00-00 00:00:00'],
      ],
      dragMode: 'zoom',
      groupHideComponent: [[], [], [], []],
      clearShowOverlay: null,
      methodsResample: null,
      opResampleTemp: [
        { max: 1, value: 1 },
        { max: 1, value: 1 },
        { max: 1, value: 1 },
        { max: 1, value: 1 },
      ],
      checkMultiYAxis: false,
      funcEventPlotlyRelayout: null,
    }
  },
  created() {
    try {
      let autoGroup = _.cloneDeep(this.autoGroup)
      if (autoGroup) {
        this.autoLocationSelected = {
          select: this.getDataLocationItemByGroup(this.autoGroupData[autoGroup]),
        }
      }
    } catch {}
  },
  mounted() {
    var self = this
    document.getElementsByClassName('timeseries-container')[0].onclick = (e) => {
      if (e.target.getAttribute('data-subplot')) {
        let arrayEle = document.querySelectorAll('.timeseries-container .nsewdrag.drag.active')
        for (let i = 0; i < arrayEle.length; i++) {
          arrayEle[i].classList.remove('active')
        }
        e.target.classList.add('active')
        let idChartSelect = e.target.getAttribute('data-subplot')
        if (idChartSelect === 'xy' || idChartSelect === 'xy5') this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { selectTimeseries: 'xy1' })
        if (idChartSelect === 'x2y2' || idChartSelect === 'x2y6') this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { selectTimeseries: 'xy2' })
        if (idChartSelect === 'x3y3' || idChartSelect === 'x3y7') this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { selectTimeseries: 'xy3' })
        if (idChartSelect === 'x4y4' || idChartSelect === 'x4y8') this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { selectTimeseries: 'xy4' })
      }
    }
    setTimeout(() => {
      let objDatasourceSelect = []
      let cloneArrayDataSource = _.cloneDeep(this.arrayDatasourceSelect)
      for (let i = 0; i < cloneArrayDataSource.length; i++) {
        objDatasourceSelect = objDatasourceSelect.concat(cloneArrayDataSource[i].datasourceSelected)
      }
      if (this.datasourceSelected.length !== 0 && objDatasourceSelect.length === 0) {
        for (let i = 0; i < cloneArrayDataSource.length; i++) {
          if (cloneArrayDataSource[i].id === 'xy1') {
            cloneArrayDataSource[i].datasourceSelected = _.cloneDeep(this.datasourceSelected)
          }
        }
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { arrayDatasourceSelect: cloneArrayDataSource })
      }
    }, 100)
  },
  computed: {
    changeOption() {
      return `${JSON.stringify(this.arrayDatasourceSelect)}_${JSON.stringify(this.showLegend)}_${JSON.stringify(this.selectedLayout)}_${JSON.stringify(this.selectedGroup)}`
    },
    resample() {
      return this.$store.state.tabs[this.$options.name].resample
    },
    typeSelected() {
      return this.$store.state.tabs[this.$options.name].typeSelected
    },
    arrayDatasourceSelect() {
      return this.$store.state.tabs[this.$options.name].arrayDatasourceSelect
    },
    selectTimeseries() {
      return this.$store.state.tabs[this.$options.name].selectTimeseries
    },
    selectedLayout: {
      get() {
        return this.$store.state.tabs[this.$options.name].selectedLayout
      },
      set(selectedLayout) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { selectedLayout })
      },
    },
    showYear() {
      return this.$store.state.tabs[this.$options.name].year
    },
    showLegend() {
      return this.$store.state.tabs[this.$options.name].legend
    },
    markerColor() {
      let colors = this.$store.state.tabs[this.$options.name].colors
      colors = colors && colors.length > 0 ? colors : OPTION_PLOTLY_PALETTE.D3
      return colors
    },
    sizeWidth() {
      return this.$store.state.tabs[this.$options.name].sizeWidth
    },
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date })
      },
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    liveMode() {
      try {
        let check = false
        if (!this.datasources || !this.datasourceSelected) return false
        let arryIdDatasources = []
        this.datasources.map((data) => {
          arryIdDatasources.push(data.id)
        })
        arryIdDatasources = _.unionBy(arryIdDatasources)
        this.datasourceSelected.map((data) => {
          let indexData = arryIdDatasources.indexOf(data.datasource)
          if (indexData > -1 && this.datasources[indexData].live) {
            check = true
            return check
          }
        })
        return check
      } catch {}
      return false
    },
    autoGroup() {
      return this.$store.state.tabs.timeseries.autoGroup
    },

    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries')
    },
    autoGroupData() {
      let result = {}
      for (let i = 0; i < this.datasources.length; i++) {
        const metadata = this.$db[this.datasources[i].id]
        if (!metadata.autoGroups) continue
        const autoGroupKeys = Object.keys(metadata.autoGroups)
        autoGroupKeys.map((key) => {
          if (!result[key]) result[key] = metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`))
          else result[key] = result[key].concat(metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`)))
        })
      }
      return result
    },
    drawBackground() {
      return this.$store.state.tabs.timeseries.drawBackground
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
  },
  watch: {
    markerColor() {
      this.changeMarkerColor = true
    },
    changeOption() {
      this.setChart()
      this.changeMarkerColor = false
    },
    selectTimeseries() {
      for (let i = 0; i < this.arrayDatasourceSelect.length; i++) {
        if (this.selectTimeseries === this.arrayDatasourceSelect[i].id) {
          this.indexDatasourceSeleted = i
          this.datasourceSelected = this.arrayDatasourceSelect[i].datasourceSelected
        }
      }
      this.flagSelectTimeseries = true
    },
    datasourceSelected(value) {
      let cloneArrayDataSelect = _.cloneDeep(this.arrayDatasourceSelect)
      for (let i = 0; i < cloneArrayDataSelect.length; i++) {
        if (this.selectTimeseries === cloneArrayDataSelect[i].id) cloneArrayDataSelect[i].datasourceSelected = value
      }
      this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { arrayDatasourceSelect: cloneArrayDataSelect })
      if (!value || value.length === 0) {
        this.opResampleTemp = [
          { max: 1, value: 1 },
          { max: 1, value: 1 },
          { max: 1, value: 1 },
          { max: 1, value: 1 },
        ]
        return
      }

      if (!this.flagSelectTimeseries) this.moveToEnd++
      setTimeout(() => {
        this.flagSelectTimeseries = false
      }, 0)
    },
    selectedLayout() {
      let cloneArrayDataSelect = _.cloneDeep(this.arrayDatasourceSelect)
      if (this.selectedLayout === '1x0') {
        this.setGridTimeseries = { rows: 1, columns: 1, pattern: 'independent' }
        for (let i = 0; i < cloneArrayDataSelect.length; i++) {
          if (cloneArrayDataSelect[i].id === 'xy1') cloneArrayDataSelect[i].visible = true
          else cloneArrayDataSelect[i].visible = false
        }
      }
      if (this.selectedLayout === '2x0') {
        this.setGridTimeseries = { rows: 2, columns: 1, pattern: 'independent' }
        for (let i = 0; i < cloneArrayDataSelect.length; i++) {
          if (cloneArrayDataSelect[i].id === 'xy1' || cloneArrayDataSelect[i].id === 'xy2') cloneArrayDataSelect[i].visible = true
          else cloneArrayDataSelect[i].visible = false
        }
      }
      if (this.selectedLayout === '1x1') {
        this.setGridTimeseries = { rows: 1, columns: 2, pattern: 'independent' }
        for (let i = 0; i < cloneArrayDataSelect.length; i++) {
          if (cloneArrayDataSelect[i].id === 'xy1' || cloneArrayDataSelect[i].id === 'xy2') cloneArrayDataSelect[i].visible = true
          else cloneArrayDataSelect[i].visible = false
        }
      }
      if (this.selectedLayout === '1x2') {
        this.setGridTimeseries = { rows: 2, columns: 2, pattern: 'independent' }
        for (let i = 0; i < cloneArrayDataSelect.length; i++) {
          if (cloneArrayDataSelect[i].id === 'xy1' || cloneArrayDataSelect[i].id === 'xy2' || cloneArrayDataSelect[i].id === 'xy3') cloneArrayDataSelect[i].visible = true
          else cloneArrayDataSelect[i].visible = false
        }
      }
      if (this.selectedLayout === '2x1') {
        this.setGridTimeseries = { rows: 2, columns: 2, pattern: 'independent' }
        for (let i = 0; i < cloneArrayDataSelect.length; i++) {
          if (cloneArrayDataSelect[i].id === 'xy1' || cloneArrayDataSelect[i].id === 'xy2' || cloneArrayDataSelect[i].id === 'xy3') cloneArrayDataSelect[i].visible = true
          else cloneArrayDataSelect[i].visible = false
        }
      }
      if (this.selectedLayout === '2x2') {
        this.setGridTimeseries = { rows: 2, columns: 2, pattern: 'independent' }
        for (let i = 0; i < cloneArrayDataSelect.length; i++) {
          cloneArrayDataSelect[i].visible = true
        }
      }
      this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { selectTimeseries: 'xy1', arrayDatasourceSelect: cloneArrayDataSelect })
      // chọn chart đầu  tiên
      let arrayEle = document.querySelectorAll('.timeseries-container .nsewdrag.drag.active')
      for (let i = 0; i < arrayEle.length; i++) {
        arrayEle[i].classList.remove('active')
      }
      if (document.querySelectorAll('.timeseries-container .nsewdrag.drag').length !== 0) {
        document.querySelectorAll('.timeseries-container .nsewdrag.drag')[0].classList.add('active')
      }

      this.setChart()
    },
    scale() {
      this.setChart()
    },
    date: {
      deep: true,
      handler() {
        this.setChart()
        this.checkZoom = [false, false, false, false]
      },
    },
    async countLiveMode() {
      this.setChart()
    },
    autoGroup: {
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal && !oldVal) return
        if (oldVal && newVal === oldVal) {
          this.autoLocationSelected = {
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        } else {
          this.autoLocationSelected = {
            unSelect: this.getDataLocationItemByGroup(this.autoGroupData[oldVal]),
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        }
      },
    },
    drawBackground() {
      this.setChart()
    },
    $route(route) {
      if (route && route.name != 'map') {
        this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', false)
      }
      try {
        if (route.name == 'timeseries') {
          this.eventPlotlyLayout()
        }
      } catch {}
    },
  },
  methods: {
    async setChart() {
      let traces = []
      let chartData = []
      let resampleCount = 0
      let imagesBackground = [{}]
      let arrayMinMax = [
        { valueDataMin: +Infinity, valueDataMax: -Infinity },
        { valueDataMin: +Infinity, valueDataMax: -Infinity },
        { valueDataMin: +Infinity, valueDataMax: -Infinity },
        { valueDataMin: +Infinity, valueDataMax: -Infinity },
      ]
      let arrDash = ['solid', 'dashdot', 'dot', 'solid']
      let checkMultiYAxis = false
      for (let i = 0; i < this.arrayDatasourceSelect.length; i++) {
        if (!this.arrayDatasourceSelect[i].visible) continue
        if (this.arrayDatasourceSelect[i].datasourceSelected.length === 0) traces.push({ xaxis: this.arrayDatasourceSelect[i].xaxis, yaxis: this.arrayDatasourceSelect[i].yaxis })
        if (!this.arrayDatasourceSelect[i].datasourceSelected.length) {
          this.$store.commit(`tabs/UPDATE_RESAMPLE_TIMESERIES`, { indexDatasourceSeleted: i, resample: { max: 1, value: 1 } })
          continue
        }
        try {
          let traceCount = 0
          let lineWidth = null
          let markerSize = null
          let count = 0
          let countLine = 0
          let resampleStore = _.cloneDeep(this.arrayDatasourceSelect[i].resample)
          let maxResample = 1
          let valueResample = resampleStore.value
          const markerColor = this.arrayDatasourceSelect[i].colors || OPTION_PLOTLY_PALETTE.D3
          let fillData = this.arrayDatasourceSelect[i].fillData
          if (this.arrayDatasourceSelect[i].sizeWidth.default == true) {
            lineWidth = this.arrayDatasourceSelect[i].sizeWidth.width
            markerSize = this.arrayDatasourceSelect[i].sizeWidth.size
          }
          const timeseriesDatasource = this.arrayDatasourceSelect[i].datasourceSelected.filter((ds) => ds.location)
          if (timeseriesDatasource.length !== 0) {
            // Min date and max date (range case and single case)
            let minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
            let maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''
            if (this.arrayDatasourceSelect[i].resample.autoResample && this.checkZoom[i] == true && this.dateZoom[i][0] && this.dateZoom[i][1]) {
              minDate = this.dateZoom[i][0]
              maxDate = this.dateZoom[i][1]
            }
            this.renderDate = [minDate, maxDate] //SAVE CURRENT DATE RENDERING
            // Group by datasource
            const datasourceGrouped = _.groupBy(timeseriesDatasource, (d) => d.datasource)
            for (const datasourceId in datasourceGrouped) {
              const locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
                return data.location + '-' + data.item
              })
              const multiYAxisItems = _.map(datasourceGrouped[datasourceId], (data) => {
                return data.multiYAxis
              })
              const recordsAll = await this.selectAllByLocationsItems(datasourceId, locationsItems, this.scale)
              const maxResampleTemp = Math.ceil(recordsAll.dates.length / this.canvasSize)
              maxResample = maxResampleTemp > maxResample ? maxResampleTemp : maxResample
              let records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
              if (!records.dates) continue
              // resample
              let x = records.dates
              let countAutoGroup = Math.ceil(x.length / this.canvasSize)
              if (countAutoGroup <= 0) countAutoGroup = 1
              if (this.arrayDatasourceSelect[i].resample.autoResample && x.length > this.canvasSize) {
                if (!this.arrayDatasourceSelect[i].resample.autoGroup) {
                  x = _.zipWith(_.chunk(records.dates, valueResample), (arr) => arr[0])
                  resampleCount += Number(valueResample)
                  this.methodsResample = null
                } else {
                  records = this.resampleData(records, 0, countAutoGroup)
                  x = records.dates
                  resampleCount += Number(countAutoGroup)
                }
              }
              let countlocationItem = 0
              for (const locationItem of locationsItems) {
                countlocationItem++
                countLine = Math.floor(count / 10)
                if (countLine > arrDash.length - 1) {
                  count = 0
                  countLine = 0
                }
                const [location, item] = splitByLastIndex(locationItem, '-')
                const name = locationItem.replace('-', ' - ')
                let visible = true
                if (this.selectedGroup) {
                  if (Object.keys(this.selectedGroup).length > 0 && !this.selectedGroup.items.includes(location)) {
                    visible = 'legendonly'
                  }
                } else {
                  if (this.groupHideComponent[i].length > 0 && this.groupHideComponent[i].includes(name)) {
                    visible = 'legendonly'
                  }
                }
                let y = records[locationItem]
                let minY = _.min(y)
                let maxY = _.max(y)
                if (minY < arrayMinMax[i].valueDataMin) arrayMinMax[i].valueDataMin = minY
                if (maxY > arrayMinMax[i].valueDataMax) arrayMinMax[i].valueDataMax = maxY

                if (fillData) {
                  switch (fillData) {
                    case this.fillDataArr[0]: {
                      y = replaceNaWithZero(y)
                      break
                    }
                    case this.fillDataArr[1]: {
                      y = replaceNaWithForwardValue(y)
                      break
                    }
                    case this.fillDataArr[2]: {
                      y = replaceNaWithLinearInterpolation(y)
                      break
                    }
                  }
                }
                if (this.arrayDatasourceSelect[i].resample.autoResample && y.length > this.canvasSize && !this.arrayDatasourceSelect[i].resample.autoGroup) {
                  y = _.zipWith(_.chunk(records[locationItem], valueResample), (arr) => _.mean(arr))
                }
                let color = markerColor[traceCount % markerColor.length]
                let flagMultiYAxis = multiYAxisItems[countlocationItem - 1] ? true : false
                if (flagMultiYAxis) {
                  checkMultiYAxis = true
                }
                traces.push({ xaxis: this.arrayDatasourceSelect[i].xaxis, yaxis: flagMultiYAxis ? 'y' + (parseInt(this.arrayDatasourceSelect[i].yaxis.substring(1)) + 4) : this.arrayDatasourceSelect[i].yaxis, name: name, visible: visible, x: x, y: y, type: 'scattergl', mode: this.arrayDatasourceSelect[i].typeSelected, line: { width: lineWidth, dash: arrDash[countLine] }, marker: { size: markerSize, color } })
                chartData = chartData.concat(y)
                traceCount++
                count++
              }
            }
          }
          if (i === this.indexDatasourceSeleted) {
            valueResample = valueResample > maxResample ? maxResample : valueResample
            this.opResampleTemp[i] = { max: maxResample, value: valueResample }
          }
        } catch (err) {}
      }
      if (this.checkMultiYAxis != checkMultiYAxis) {
        this.checkMultiYAxis = checkMultiYAxis
      }
      this.chart = {
        data: traces,
        layout: {
          showlegend: this.showLegend,
          grid: this.setGridTimeseries,
          images: imagesBackground,
          xaxis: {
            domain: this.selectedLayout === '1x0' || this.selectedLayout === '2x0' ? [0, 1] : [0, 0.45],
            anchor: 'y5',
          },
          yaxis5: {
            domain: this.selectedLayout === '1x0' || this.selectedLayout === '1x1' || this.selectedLayout === '1x2' ? [0, 1] : [0.6, 1],
            anchor: 'x1',
            autorange: this.arrayDatasourceSelect[0].autorange,
            range: [arrayMinMax[0].valueDataMin, arrayMinMax[0].valueDataMax],
            side: 'right',
            overlaying: 'y1',
          },
          yaxis: {
            domain: this.selectedLayout === '1x0' || this.selectedLayout === '1x1' || this.selectedLayout === '1x2' ? [0, 1] : [0.6, 1],
            anchor: 'x1',
            autorange: this.arrayDatasourceSelect[0].autorange,
            range: [arrayMinMax[0].valueDataMin, arrayMinMax[0].valueDataMax],
          },
          xaxis2: {
            domain: this.selectedLayout === '2x0' ? [0, 1] : [0.55, 0.97],
            anchor: 'y6',
          },
          yaxis2: {
            domain: this.selectedLayout === '1x1' || this.selectedLayout === '2x1' ? [0, 1] : this.selectedLayout === '2x0' ? [0, 0.45] : [0.6, 1],
            anchor: 'x2',
            autorange: this.arrayDatasourceSelect[1].autorange,
            range: [arrayMinMax[1].valueDataMin, arrayMinMax[1].valueDataMax],
          },
          xaxis3: {
            domain: this.selectedLayout === '1x2' ? [0.55, 0.97] : [0, 0.45],
            anchor: 'y7',
          },
          yaxis3: {
            domain: [0, 0.45],
            anchor: 'x3',
            autorange: this.arrayDatasourceSelect[2].autorange,
            range: [arrayMinMax[2].valueDataMin, arrayMinMax[2].valueDataMax],
          },
          xaxis4: {
            domain: [0.55, 0.97],
            anchor: 'y8',
          },
          yaxis4: {
            domain: [0, 0.45],
            anchor: 'x4',
            autorange: this.arrayDatasourceSelect[3].autorange,
            range: [arrayMinMax[3].valueDataMin, arrayMinMax[3].valueDataMax],
          },

          yaxis6: {
            domain: this.selectedLayout === '1x1' || this.selectedLayout === '2x1' ? [0, 1] : this.selectedLayout === '2x0' ? [0, 0.45] : [0.6, 1],
            anchor: 'x2',
            autorange: this.arrayDatasourceSelect[1].autorange,
            range: [arrayMinMax[1].valueDataMin, arrayMinMax[1].valueDataMax],
            overlaying: 'y2',
            side: 'right',
          },

          yaxis7: {
            domain: [0, 0.45],
            anchor: 'x3',
            autorange: this.arrayDatasourceSelect[2].autorange,
            range: [arrayMinMax[2].valueDataMin, arrayMinMax[2].valueDataMax],
            overlaying: 'y3',
            side: 'right',
          },
          yaxis8: {
            domain: [0, 0.45],
            anchor: 'x4',
            autorange: this.arrayDatasourceSelect[3].autorange,
            range: [arrayMinMax[3].valueDataMin, arrayMinMax[3].valueDataMax],
            overlaying: 'y4',
            side: 'right',
          },
        },
      }
      this.chartData = chartData
      this.resampleCount = resampleCount
    },
    rotateBase64(srcBase64, degrees) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const image = new Image()
      image.src = srcBase64
      canvas.width = degrees % 180 === 0 ? image.width : image.height
      canvas.height = degrees % 180 === 0 ? image.height : image.width

      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(Math.PI)
      ctx.scale(-1, 1)
      ctx.drawImage(image, image.width / -2, image.height / -2)
      return canvas.toDataURL()
    },

    async getDataCSV() {
      let dataCSV = ''
      let id = 'ID'
      let item = 'ITEM'
      let unit = 'UNIT'
      let dataLines = []
      let lineCSV = ''
      let recordsDate = []
      // Min date and max date (range case and single case)
      const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
      const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''
      const datasourceGrouped = _.groupBy(this.datasourceSelected, (d) => d.datasource)
      // calculator commma length
      let dataSort = {}
      for (const datasourceId in datasourceGrouped) {
        const dataItems = _.cloneDeep(datasourceGrouped[datasourceId])
        let dataItemsSort
        try {
          dataItemsSort = _.sortBy(dataItems, ['item', 'location'])
        } catch {
          dataItemsSort = dataItems
        }
        dataSort[datasourceId] = dataItemsSort
        const locationsItems = _.map(dataItemsSort, (data) => {
          return data.location + '-' + data.item
        })
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
        if (records.dates && records.dates != null) {
          recordsDate = recordsDate[0] && new Date(recordsDate[0]) > new Date(records.dates[0]) ? [...new Set([...records.dates, ...recordsDate])] : [...new Set([...recordsDate, ...records.dates])]
        }
      }
      // Group by datasource
      for (const datasourceId in dataSort) {
        const locationsItems = _.map(dataSort[datasourceId], (data) => {
          let unitTemp = ','
          try {
            unitTemp = `,${this.$db[data.datasource].items[data.item].unit}`
          } catch {}
          id += ',' + data.location
          item += ',' + data.item
          unit += unitTemp
          return data.location + '-' + data.item
        })
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
        recordsDate.forEach((date, index) => {
          dataLines[index] = dataLines[index] && dataLines[index] != '' ? dataLines[index] : date
          for (let m = 0; m < locationsItems.length; m++) {
            let id = records.dates.indexOf(date)
            if (id != -1) {
              dataLines[index] += ',' + records[locationsItems[m]][id]
            } else {
              dataLines[index] += ','
            }
          }
        })
      }
      dataLines.forEach((line) => {
        lineCSV += line + '\r\n'
      })
      if (lineCSV != '') {
        dataCSV += id + '\r\n' + item + '\r\n' + unit + '\r\n' + lineCSV
      }
      return dataCSV
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_Timeseries')
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_Timeseries')
    },
    async downloadCsv() {
      let data = await this.getDataCSV()
      this.$refs.chart.asCSV(this.tempProject.name + '_Timeseries', data)
    },
    getDataLocationItemByGroup(groupData) {
      if (!groupData) return []
      let result = []
      for (let i = 0; i < groupData.length; i++) {
        const [locationItems, datasourceId] = splitByLastIndex(groupData[i], '|')
        const [location, item] = splitByLastIndex(locationItems, '*')
        result.push({
          datasource: datasourceId,
          item,
          location,
        })
      }
      return result
    },
    resampleData(data, iResample = 0, countAutoGroup, method = 'mean') {
      try {
        // resample -> from hourly -> daily -> monthly -> yearly -> none
        const sttResample = ['hourly', 'daily', 'monthly', 'yearly', 'none']
        if (iResample > sttResample.length) return data
        let frequency = sttResample[iResample]
        if (frequency === 'none') {
          for (let key in data) {
            if (key == 'dates') {
              data.dates = _.zipWith(_.chunk(data.dates, countAutoGroup), (arr) => arr[0])
            } else {
              data[key] = _.zipWith(_.chunk(data[key], countAutoGroup), (arr) => _.mean(arr))
            }
          }
          return data
        } else {
          const rows = _.zip(...Object.values(data))
          let groups
          let converted = []
          groups = _.groupBy(rows, (row) => {
            if (frequency === 'yearly') return row[0].substring(0, 4)
            if (frequency === 'monthly') return row[0].substring(0, 7)
            if (frequency === 'daily') return row[0].substring(0, 10)
            if (frequency === 'hour') return row[0].substring(0, 13)
            if (frequency === 'minute') return row[0].substring(0, 16)
            return row[0]
          })
          if (Object.keys(groups).length > this.canvasSize) {
            iResample++
            return this.resampleData(data, iResample, countAutoGroup)
          }
          // get columns need calculate sum|mean
          for (const key in groups) {
            let row = []
            row.push(dayjs(key).format('YYYY-MM-DD HH:mm:ss'))
            let colsData = _.zip(...groups[key])
            for (let i = 1; i < colsData.length; i++) {
              let value = Math.round(_[method](colsData[i]) * 1e6) / 1e6
              row.push(value)
            }
            converted.push(row)
          }
          converted = _.zipObject(Object.keys(data), _.zip(...converted))
          this.methodsResample = sttResample[iResample]
          return converted
        }
      } catch {
        return data
      }
    },
    eventPlotlyLayout() {
      const self = this
      try {
        if (self.funcEventPlotlyRelayout) {
          clearTimeout(self.funcEventPlotlyRelayout)
        }
        self.funcEventPlotlyRelayout = setTimeout(() => {
          let plotlyElement = document.getElementById('timeseries-plotly-chart')
          if (plotlyElement) {
            plotlyElement.on('plotly_relayout', function (eventdata) {
              try {
                let layoutOrigin = plotlyElement.layout
                let layoutTemp = {
                  xaxis: {},
                  xaxis2: {},
                  xaxis3: {},
                  xaxis4: {},
                  yaxis: {},
                  yaxis2: {},
                  yaxis3: {},
                  yaxis4: {},
                  yaxis5: {},
                  yaxis6: {},
                  yaxis7: {},
                  yaxis8: {},
                }
                if (layoutOrigin.title) {
                  layoutTemp.title = layoutOrigin.title
                }
                if (layoutOrigin.xaxis && layoutOrigin.xaxis.title) {
                  layoutTemp.xaxis.title = layoutOrigin.xaxis.title
                }
                if (layoutOrigin.xaxis2 && layoutOrigin.xaxis2.title) {
                  layoutTemp.xaxis2.title = layoutOrigin.xaxis2.title
                }
                if (layoutOrigin.xaxis3 && layoutOrigin.xaxis3.title) {
                  layoutTemp.xaxis3.title = layoutOrigin.xaxis3.title
                }
                if (layoutOrigin.xaxis4 && layoutOrigin.xaxis4.title) {
                  layoutTemp.xaxis4.title = layoutOrigin.xaxis4.title
                }
                if (layoutOrigin.yaxis && layoutOrigin.yaxis.title) {
                  layoutTemp.yaxis.title = layoutOrigin.yaxis.title
                }
                if (layoutOrigin.yaxis2 && layoutOrigin.yaxis2.title) {
                  layoutTemp.yaxis2.title = layoutOrigin.yaxis2.title
                }
                if (layoutOrigin.yaxis3 && layoutOrigin.yaxis3.title) {
                  layoutTemp.yaxis3.title = layoutOrigin.yaxis3.title
                }
                if (layoutOrigin.yaxis4 && layoutOrigin.yaxis4.title) {
                  layoutTemp.yaxis4.title = layoutOrigin.yaxis4.title
                }
                if (layoutOrigin.yaxis5 && layoutOrigin.yaxis5.title) {
                  layoutTemp.yaxis5.title = layoutOrigin.yaxis5.title
                }
                if (layoutOrigin.yaxis6 && layoutOrigin.yaxis6.title) {
                  layoutTemp.yaxis6.title = layoutOrigin.yaxis6.title
                }
                if (layoutOrigin.yaxis7 && layoutOrigin.yaxis7.title) {
                  layoutTemp.yaxis7.title = layoutOrigin.yaxis7.title
                }
                if (layoutOrigin.yaxis8 && layoutOrigin.yaxis8.title) {
                  layoutTemp.yaxis8.title = layoutOrigin.yaxis8.title
                }
                if (self.$route && self.$route.name == 'timeseries') {
                  self.$store.commit(`tabs/SET_TIMESERIES`, { chartLayout: layoutTemp })
                }
              } catch {}
              try {
                if (eventdata.dragmode) {
                  self.dragMode = eventdata.dragmode
                }
                if (eventdata['xaxis.showspikes'] === false && eventdata['yaxis.showspikes'] === false) {
                  self.groupHideComponent = [[], [], [], []]
                }
                let xAxis01 = eventdata['xaxis.range[0]']
                let xAxis11 = eventdata['xaxis.range[1]']
                let xAxis02 = eventdata['xaxis2.range[0]']
                let xAxis12 = eventdata['xaxis2.range[1]']
                let xAxis03 = eventdata['xaxis3.range[0]']
                let xAxis13 = eventdata['xaxis3.range[1]']
                let xAxis04 = eventdata['xaxis4.range[0]']
                let xAxis14 = eventdata['xaxis4.range[1]']
                if (self.dragMode == 'zoom') {
                  if (self.arrayDatasourceSelect[0].resample.autoResample && ((xAxis01 && xAxis11) || (eventdata['xaxis.autorange'] && eventdata['yaxis.autorange'] && !eventdata['xaxis2.autorange']))) {
                    self.checkZoom[0] = true
                    self.dateZoom[0] = [xAxis01, xAxis11]
                    self.setChart()
                  }
                  if (self.arrayDatasourceSelect[1].resample.autoResample && ((xAxis02 && xAxis12) || (eventdata['xaxis2.autorange'] && eventdata['yaxis2.autorange'] && !eventdata['xaxis3.autorange']))) {
                    self.checkZoom[1] = true
                    self.dateZoom[1] = [xAxis02, xAxis12]
                    self.setChart()
                  }
                  if (self.arrayDatasourceSelect[2].resample.autoResample && ((xAxis03 && xAxis13) || (eventdata['xaxis3.autorange'] && eventdata['yaxis3.autorange'] && !eventdata['xaxis4.autorange']))) {
                    self.checkZoom[2] = true
                    self.dateZoom[2] = [xAxis03, xAxis13]
                    self.setChart()
                  }
                  if (self.arrayDatasourceSelect[3].resample.autoResample && ((xAxis04 && xAxis14) || (eventdata['xaxis4.autorange'] && eventdata['yaxis4.autorange'] && !eventdata['xaxis.autorange']))) {
                    self.checkZoom[3] = true
                    self.dateZoom[3] = [xAxis04, xAxis14]
                    self.setChart()
                  }
                  if (self.arrayDatasourceSelect[0].resample.autoResample && self.arrayDatasourceSelect[1].resample.autoResample && self.arrayDatasourceSelect[2].resample.autoResample && self.arrayDatasourceSelect[3].resample.autoResample && eventdata['xaxis.autorange'] && eventdata['xaxis2.autorange'] && eventdata['xaxis3.autorange'] && eventdata['xaxis4.autorange']) {
                    self.checkZoom = [true, true, true, true]
                    self.dateZoom = [[,], [,], [,], [,]]
                    self.setChart()
                  }
                }
              } catch {}
            })
            plotlyElement.on('plotly_legendclick', function (plotly) {
              try {
                let indexLayoutSubitem = 0
                let indexLayoutSubitem0 = indexLayoutSubitem + self.arrayDatasourceSelect[0].datasourceSelected.length
                let indexLayoutSubitem1 = indexLayoutSubitem0 + self.arrayDatasourceSelect[1].datasourceSelected.length
                let indexLayoutSubitem2 = indexLayoutSubitem1 + self.arrayDatasourceSelect[2].datasourceSelected.length
                let indexLayoutSubitem3 = indexLayoutSubitem2 + self.arrayDatasourceSelect[3].datasourceSelected.length
                let index = plotly.expandedIndex
                let name = plotly.data[index].name
                let idGroupHideComponentPush
                switch (true) {
                  case index >= indexLayoutSubitem + 1 && index <= indexLayoutSubitem0 + 1: {
                    idGroupHideComponentPush = 0
                    break
                  }
                  case index >= indexLayoutSubitem0 + 2 && index <= indexLayoutSubitem1 + 2: {
                    idGroupHideComponentPush = 1
                    break
                  }
                  case index >= indexLayoutSubitem1 + 3 && index <= indexLayoutSubitem2 + 3: {
                    idGroupHideComponentPush = 2
                    break
                  }
                  case index >= indexLayoutSubitem2 + 4 && index <= indexLayoutSubitem3 + 4: {
                    idGroupHideComponentPush = 3
                    break
                  }
                }
                if (idGroupHideComponentPush >= 0) {
                  let indexSearch = self.groupHideComponent[idGroupHideComponentPush].indexOf(name)
                  if (indexSearch > -1) {
                    self.groupHideComponent[idGroupHideComponentPush].splice(indexSearch, 1)
                  } else {
                    self.groupHideComponent[idGroupHideComponentPush].push(name)
                  }
                }
              } catch {}
            })
            plotlyElement.on('plotly_doubleclick', function (data) {
              self.groupHideComponent = [[], [], [], []]
            })
          }
        }, 1000)
      } catch {}
    },
  },
}
</script>

<style>
.timeseries-container .nsewdrag.drag.active {
  stroke-width: 1 !important;
  stroke: #397bff;
}
</style>

<style scoped>
.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-icon {
  width: 50px;
  height: 50px;
  /* background-image: url('/static/images/timeseries/loading.svg');
  background-repeat: no-repeat;
  background-size: contain; */
}

.loading-icon {
  -webkit-animation: loading 1s infinite;
  -moz-animation: loading 1s infinite;
  -ms-animation: loading 1s infinite;
  animation: loading 1s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes loading {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.btn-flat-custom {
  background: #397bff;
  color: #fff;
}
.btn-flat-custom:hover {
  opacity: 0.9;
}
</style>

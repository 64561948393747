<template>
  <b-card no-body class="timeseries-chart-card">
    <b-card-body>
      <div class="timeseries-chart" id="timeseries-plotly-chart" ref="chart" :style="{ visibility: showChart ? 'visible' : 'hidden', opacity: showChart ? 1 : 0 }"></div>
      <div class="timeseries-chart empty-chart" :style="{ visibility: showChart ? 'hidden' : 'visible' }">
        <EmptyChart :description="$t('tips_timeseries')" />
      </div>
      <ChartYearText ref="refChartYearText" v-if="showYear && showChart && $parent.$parent.yearText" :text="$parent.$parent.yearText" />
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash');
import Plotly from 'plotly.js-dist';
import DownloadMixin from '@/mixins/DownloadMixin';
import EmptyChart from '../../common/EmptyChart.vue';
import ChartYearText from '../../common/ChartYearText.vue';
import i18n from '@/libs/i18n';
import { CustomLayout } from '@/mixins/ThemeMixin.js';
import { locale } from '@/scripts/plotly/locale.js';

const LAYOUT = {
  hovermode: 'closest',
  yaxis: { automargin: true },
  xaxis: { automargin: true },
};

const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage'],
};

export default {
  props: ['chart', 'showYear', 'nameComponent', 'indexDatasourceSeleted', 'checkMultiYAxis'],
  mixins: [DownloadMixin, CustomLayout],
  components: { EmptyChart, ChartYearText, locale },
  data() {
    return {
      localePlotly: 'en',
      annotations: undefined,
    };
  },
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale);
    this.updateChart();
    // listen to div resize => chart resize
    this.$nextTick(() => {
      new ResizeObserver(() => {
        if (this.$route.name == 'timeseries' && this.$refs.chart && this.$refs.chart.style && this.$refs.chart.style.visibility && this.$refs.chart.style.visibility !== 'hidden') {
          if (this.checkMultiYAxis) {
            setTimeout(() => {
              try {
                let legendX = 1;
                let boudingBoxDomChart = this.$refs.chart.getBoundingClientRect();
                let domLegend = document.querySelector('#timeseries-plotly-chart .legend');
                let boudingBoxdomLegend = domLegend.getBoundingClientRect();
                legendX = 80 / (boudingBoxDomChart.width - boudingBoxdomLegend.width - 50) + 1;
                if (legendX >= 1) {
                  this.$refs.chart.layout.legend = {
                    x: legendX,
                    y: 1,
                  };
                }
                Plotly.Plots.resize(this.$refs.chart);
              } catch {}
            }, 0);
          }

          Plotly.Plots.resize(this.$refs.chart);
        }
      }).observe(this.$refs.chart);
    });
  },
  computed: {
    showChart() {
      if (this.selectedLayout === '1x0') return this.chart.data && this.chart.data.length > 0 && this.chart.data[0].x && this.chart.data[0].y;
      else return this.chart.data && this.chart.data.length > 0;
    },
    locale() {
      return i18n.locale;
    },
    arrayDatasourceSelect() {
      return this.$store.state.tabs[this.nameComponent].arrayDatasourceSelect;
    },
    selectTimeseries() {
      return this.$store.state.tabs[this.nameComponent].selectTimeseries;
    },
    selectedLayout() {
      return this.$store.state.tabs[this.nameComponent].selectedLayout;
    },
    name() {
      return this.$options.name;
    },
    editable() {
      return this.$store.state.tabs[this.nameComponent].editable;
    },
    chartFontFamily() {
      return this.$store.state.tabs[this.nameComponent].chartFontFamily;
    },
    chartTextSize() {
      return this.$store.state.tabs[this.nameComponent].chartTextSize;
    },
    chartTextColor() {
      return this.$store.state.tabs[this.nameComponent].chartTextColor;
    },
    chartFont() {
      let font = {};
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily;
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize;
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor;
        }
      } catch {}
      return font;
    },
    chartLayout() {
      return this.$store.state.tabs.timeseries.chartLayout;
    },
  },
  watch: {
    chart: {
      deep: false,
      handler() {
        this.updateChart(true);
      },
    },
    locale() {
      if (this.chart.data.length > 0) {
        this.updateChart();
      }
    },
    editable() {
      if (this.chart.data.length > 0) {
        this.updateChart();
      }
    },
    chartFont: {
      deep: true,
      handler() {
        if (this.chartFont) {
          this.updateChartLayout();
        }
      },
    },
  },
  methods: {
    updateChart(isUpdate = false) {
      let _config = CONFIG;
      const self = this;
      _config.locale = i18n.locale;
      let modeBarButtonsToAdd = [];
      modeBarButtonsToAdd.push({
        name: this.$i18n.t('rotate'),
        icon: Plotly.Icons['3d_rotate'],
        direction: 'down',
        click: (gd) => {
          let cloneArrayDataSelect = _.cloneDeep(this.arrayDatasourceSelect);
          for (let i = 0; i < cloneArrayDataSelect.length; i++) {
            if (!cloneArrayDataSelect[i].visible) continue;
            if (this.selectTimeseries === cloneArrayDataSelect[i].id) {
              let autorange = cloneArrayDataSelect[i].autorange === 'reversed' ? true : 'reversed';
              cloneArrayDataSelect[i].autorange = autorange;
            }
          }
          this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { arrayDatasourceSelect: cloneArrayDataSelect });
        },
      });
      let layoutTemp = {};
      try {
        if (this.chartFont) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFont });
        }
        if (this.chartLayout) {
          layoutTemp = _.merge(layoutTemp, this.chartLayout);
        }
      } catch {}
      _config.modeBarButtonsToAdd = modeBarButtonsToAdd;
      _config = _.merge(_config, { editable: this.editable });
      if (isUpdate) {
        this.$refs.chart.layout.images = this.chart.layout.images;
        let layout = _.merge({}, this.$refs.chart.layout, this.themeLayout, LAYOUT, this.chart.layout, layoutTemp);
        if (this.checkMultiYAxis) {
          try {
            let legendX = 1;
            let boudingBoxDomChart = this.$refs.chart.getBoundingClientRect();
            let domLegend = document.querySelector('#timeseries-plotly-chart .legend');
            let boudingBoxdomLegend = domLegend.getBoundingClientRect();
            legendX = 80 / (boudingBoxDomChart.width - boudingBoxdomLegend.width - 50) + 1;
            if (legendX >= 1) {
              layout.legend = {
                x: legendX,
                y: 1,
              };
            }
          } catch {}
        }
        Plotly.react(this.$refs.chart, this.chart.data, layout, _config);
      } else {
        let layout = _.merge({}, this.themeLayout, LAYOUT, this.chart.layout, layoutTemp);
        if (this.$refs.chart && this.$refs.chart.layout) {
          if (this.$refs.chart.layout.legend) {
            layout.legend = this.$refs.chart.layout.legend;
          }
          if (this.$refs.chart.layout.title) {
            layout.title = this.$refs.chart.layout.title;
          }
          if (this.$refs.chart.layout.xaxis.title) {
            layout.xaxis.title = this.$refs.chart.layout.xaxis.title;
          }
          if (this.$refs.chart.layout.yaxis.title) {
            layout.yaxis.title = this.$refs.chart.layout.yaxis.title;
          }
        }
        Plotly.newPlot(this.$refs.chart, this.chart.data, layout, _config);
        this.$emit('updateEventPlotlyLayout');
        if (this.checkMultiYAxis) {
          setTimeout(() => {
            try {
              let legendX = 1;
              let boudingBoxDomChart = this.$refs.chart.getBoundingClientRect();
              let domLegend = document.querySelector('#timeseries-plotly-chart .legend');
              let boudingBoxdomLegend = domLegend.getBoundingClientRect();
              legendX = 80 / (boudingBoxDomChart.width - boudingBoxdomLegend.width - 50) + 1;
              if (legendX >= 1) {
                this.$refs.chart.layout.legend = {
                  x: legendX,
                  y: 1,
                };
              }
              Plotly.relayout(this.$refs.chart, this.$refs.chart.layout);
            } catch {}
          }, 0);
        }
      }
    },
    updateChartLayout() {
      setTimeout(() => {
        try {
          let layout = this.$refs.chart.layout;
          let layoutTemp = {};
          try {
            if (this.chartFont) {
              layoutTemp = _.merge(layoutTemp, { font: this.chartFont });
            }
            if (this.chartLayout) {
              layoutTemp = _.merge(layoutTemp, this.chartLayout);
            }
          } catch {}
          layout = _.merge(layout, layoutTemp);
          Plotly.relayout(this.$refs.chart, layout);
        } catch {}
      }, 300);
    },
  },
};
</script>

<style scoped>
.timeseries-chart-card .card-body {
  padding-top: 0;
}

.timeseries-chart.empty-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.timeseries-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 2rem - 36px);
}
</style>
<style>
.plot-container.plotly .user-select-none.svg-container {
  z-index: 1 !important;
}
</style>
